import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["number"]
  
  connect() {
    const endValue = parseInt(this.element.dataset.counterEndValue)
    this.animateValue(0, endValue, 5000) // 1.5 seconds duration
  }

  animateValue(start, end, duration) {
    if (start === end) return
    
    const range = end - start
    const startTime = performance.now()
    
    const updateNumber = (currentTime) => {
      const elapsed = currentTime - startTime
      const progress = Math.min(elapsed / duration, 1)
      
      // Easing function for smooth animation
      //const easeOutQuad = (x) => 1 - (1 - x) * (1 - x)
      //const easedProgress = easeOutQuad(progress)
      const easeOutExpo = (x) => x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
        const easedProgress = easeOutExpo(progress)
        
      const currentValue = Math.floor(start + (range * easedProgress))
      this.numberTarget.textContent = this.formatNumber(currentValue)
      
      if (progress < 1) {
        requestAnimationFrame(updateNumber)
      }
    }
    
    requestAnimationFrame(updateNumber)
  }

  formatNumber(number) {
    // Format numbers over 1000 as 1k, 1.1k, etc.
    return number >= 1000 ? `${(number/1000).toFixed(1)}k` : number
  }
} 