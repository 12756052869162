import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    data: Object,
    colors: Array
  }

  connect() {
    const options = {
      series: this.dataValue.series,
      chart: {
        height: 250,
        type: 'line',
        zoom: {
          enabled: false
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800
        },
        toolbar: {
          show: false
        }
      },
      colors: this.colorsValue,
      dataLabels: {
        enabled: false,
        formatter: function(val) {
          return val + ' kg'
        }
      },
      stroke: {
        width: 3,
        curve: 'smooth'
      },
      markers: {
        size: 3,
        strokeWidth: 2,
        strokeColors: this.colorsValue,
        hover: {
          size: 8
        }
      },
      grid: {
        borderColor: '#e0e6ed',
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          top: 0,
          right: 20,
          bottom: 0,
          left: 20
        },
      },
      xaxis: {
        type: 'datetime',
        categories: this.dataValue.dates,
        labels: {
          formatter: function (value, timestamp) {
            // strip milliseconds
            const time = Math.round(timestamp*1000)
            return new Date(time).toLocaleDateString('nl-NL', { day: 'numeric', month: 'short' })
          }
        }
      },
      yaxis: this.dataValue.series.map((serie, indx) => {
        const values = serie.data.map((d) => d[1]);
        const min = Math.min(...values)-(indx/12);
        const max = Math.max(...values)+((12-indx)/12);
        return {
          show: false,
          forceNiceScale: true,
          title: {
            text: 'Weight (kg)'
          },
          min: min,
          max: max
        }
      }),
      tooltip: {
        shared: true,
        followCursor: true,
        intersect: false,
        inverseOrder: true,
        fillSeriesColor: true,
        y: {
          formatter: function (y) {
            return y.toFixed(1) + " kg"
          }
        }
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'left',
        floating: false,
        offsetY: 25,
        offsetX: -5
      }
    }

    this.chart = new ApexCharts(this.element, options)
    this.chart.render()
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy()
    }
  }
} 