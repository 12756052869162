import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["frame"]

  connect() {
    this.element.addEventListener("turbo:frame-loading", this.showLoading)
    this.element.addEventListener("turbo:frame-error", this.showError)
  }

  showLoading(event) {
    event.target.innerHTML = '<div class="animate-pulse">Loading...</div>'
  }

  showError(event) {
    event.target.innerHTML = '<div class="text-red-500">Failed to load data. Please try again.</div>'
  }
} 