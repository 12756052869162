import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    data: Object
  }

  connect() {
    this.initializeChart()
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy()
    }
  }

  initializeChart() {
    const weeks = this.generateWeeksData()
    const { min, max } = this.getMinMaxValues(weeks)
    const colorSteps = this.generateColorSteps(min, max)
    const dateRanges = this.generateDateRanges()
    
    const options = {
      series: weeks,
      chart: {
        height: 300,
        type: 'heatmap',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#0284c7"],
      title: {
        text: 'Sporters per Day',
        style: {
          fontSize: '14px',
          fontWeight: 500,
          fontFamily: 'Inter, sans-serif'
        }
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const value = series[seriesIndex][dataPointIndex]
          const weekday = w.globals.labels[dataPointIndex]
          const dateRange = w.globals.seriesNames[seriesIndex]
          return `<div class="p-2">
            <div class="font-medium">${weekday}, ${dateRange}</div>
            <div>${value} sporter${value !== 1 ? 's' : ''}</div>
          </div>`
        }
      },
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: colorSteps
          },
          radius: 2,
        }
      },
      xaxis: {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        labels: {
          rotate: 0,
        }
      },
      yaxis: {
        reversed: true,
        labels: {
          formatter: (value, index) => dateRanges[index]
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        }
      }
    }

    this.chart = new ApexCharts(this.element, options)
    this.chart.render()
  }

  generateDateRanges() {
    const ranges = []
    const now = new Date()
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - (8 * 7))
    
    for (let weekNum = 0; weekNum < 8; weekNum++) {
      const weekStart = new Date(startDate)
      weekStart.setDate(weekStart.getDate() + (weekNum * 7))
      const weekEnd = new Date(weekStart)
      weekEnd.setDate(weekEnd.getDate() + 6)
      
      ranges.push(
        `${weekStart.getDate()}/${weekStart.getMonth() + 1} - ${weekEnd.getDate()}/${weekEnd.getMonth() + 1}`
      )
    }
    
    return ranges
  }

  generateColorSteps(min, max) {
    if (max === 0) return [{ from: 0, to: 0, color: '#F3F4F6' }]

    const steps = 7 // Number of color gradients we want
    const increment = (max - min) / (steps - 1)
    
    const colors = [
      '#F3F4F6', // 0 values
      '#DBEAFE',
      '#93C5FD',
      '#60A5FA',
      '#3B82F6',
      '#2563EB',
      '#1E40AF'
    ]

    const ranges = [{ from: 0, to: 0, color: colors[0] }]
    
    for (let i = 0; i < steps - 1; i++) {
      const from = min + (i * increment)
      const to = i === steps - 2 ? max : min + ((i + 1) * increment)
      ranges.push({
        from: Math.round(from * 10) / 10,  // Round to 1 decimal
        to: Math.round(to * 10) / 10,      // Round to 1 decimal
        color: colors[i + 1]
      })
    }

    return ranges
  }

  getMinMaxValues(weeks) {
    let min = Infinity
    let max = -Infinity
    
    weeks.forEach(week => {
      week.data.forEach(value => {
        if (value > 0) {  // Only consider non-zero values for minimum
          min = Math.min(min, value)
        }
        max = Math.max(max, value)
      })
    })
    
    return { min: min === Infinity ? 0 : min, max: max === -Infinity ? 0 : max }
  }

  generateWeeksData() {
    const weeks = []
    const now = new Date()
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - (8 * 7))
    
    for (let weekNum = 0; weekNum < 8; weekNum++) {
      const weekData = {
        name: weekNum + 1,
        data: Array(7).fill(0)
      }
      
      for (let day = 0; day < 7; day++) {
        const currentDate = new Date(startDate)
        currentDate.setDate(currentDate.getDate() + (weekNum * 7) + day)
        const dateString = currentDate.toISOString().split('T')[0]
        weekData.data[day] = this.dataValue[dateString] || 0
      }
      
      weeks.push(weekData)
    }
    
    return weeks
  }
}