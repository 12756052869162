import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["panel", "pinForm", "pinInput", "commentForm", "commentsList"]

  connect() {
    this.verified = false
  }

  toggleComments(event) {
    event.preventDefault()
    if (!this.verified) {
      this.showPinForm()
    } else {
      this.panelTarget.classList.toggle("hidden")
    }
  }

  showPinForm() {
    this.pinFormTarget.classList.remove("hidden")
    this.commentFormTarget.classList.add("hidden")
    this.commentsListTarget.classList.add("hidden")
  }

  async verifyPin(event) {
    event.preventDefault()
    const pin = this.pinInputTarget.value

    try {
      const response = await fetch("/verify_trainer_pin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify({ pin })
      })

      if (response.ok) {
        this.verified = true
        this.pinFormTarget.classList.add("hidden")
        this.commentFormTarget.classList.remove("hidden")
        this.commentsListTarget.classList.remove("hidden")
        this.panelTarget.classList.remove("hidden")
      } else {
        // Handle invalid PIN
        this.pinInputTarget.value = ""
        this.pinInputTarget.classList.add("border-red-500")
        // Add error message
        const errorDiv = document.createElement("div")
        errorDiv.className = "mt-1 text-sm text-red-500"
        errorDiv.textContent = "Invalid PIN. Please try again."
        this.pinFormTarget.appendChild(errorDiv)
        // Remove error message after 3 seconds
        setTimeout(() => errorDiv.remove(), 3000)
      }
    } catch (error) {
      console.error("Error verifying PIN:", error)
    }
  }

  async addComment(event) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)

    try {
      const response = await fetch(form.action, {
        method: "POST",
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        },
        body: formData
      })

      if (response.ok) {
        form.reset()
      } else if (response.status === 401) {
        this.showUnauthorizedError()
      }
    } catch (error) {
      console.error("Error adding comment:", error)
    }
  }

  showUnauthorizedError() {
    const errorDiv = document.createElement("div")
    errorDiv.className = "mt-2 p-2 text-sm text-white bg-red-500 rounded"
    errorDiv.textContent = "You are not authorized to perform this action."
    this.element.appendChild(errorDiv)
    setTimeout(() => errorDiv.remove(), 3000)
  }
} 