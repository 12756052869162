import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["chart"]
  
    
    
      disconnect() {
    if (this.chart) {
      this.chart.destroy()
    }
  }

    
  connect() {
    const options = {
      chart: {
        type: 'area',
        height: 350,
        fontFamily: 'Inter, system-ui, -apple-system, sans-serif',
        toolbar: {
          show: false
        }
      },
      series: [{
        name: 'Completed Workouts',
        data: JSON.parse(this.chartTarget.dataset.sessions || '[]')
      }],
      xaxis: {
        categories: JSON.parse(this.chartTarget.dataset.dates || '[]'),
        labels: {
          style: {
            colors: '#64748b'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#64748b'
          },
          formatter: function(val) {
            return Math.floor(val)
          }
        }
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      colors: ['#818cf8'], // Indigo color to match your stats
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [0, 90, 100]
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        borderColor: '#f1f5f9',
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      tooltip: {
        theme: 'light',
        x: {
          format: 'dd MMM'
        }
      }
    }

    this.chart = new ApexCharts(this.chartTarget, options)
    this.chart.render()
  }
} 