import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: Number }

  async togglePin(event) {
    event.preventDefault()

    try {
      const response = await fetch(`/exercise_content_comments/${this.idValue}/toggle_pin`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Accept": "text/vnd.turbo-stream.html"
        }
      })

      if (!response.ok) throw new Error("Failed to toggle pin")
    } catch (error) {
      console.error("Error toggling pin:", error)
    }
  }

  async delete(event) {
    event.preventDefault()
    
    if (!confirm("Are you sure you want to delete this comment?")) return

    try {
      const response = await fetch(`/exercise_content_comments/${this.idValue}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Accept": "text/vnd.turbo-stream.html"
        }
      })

      if (!response.ok) throw new Error("Failed to delete comment")
    } catch (error) {
      console.error("Error deleting comment:", error)
    }
  }
} 