import { Controller } from 'stimulus';
//import { Turbo } from '@hotwired/turbo-rails';


export default class extends Controller {
  static targets = [ "pinable","hideable","pininput","checksum" ]

  showTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = false
    });
  }
  showpinTargets() {
      this.pinableTargets.forEach(el => {
        //el.hidden = false
        el.classList.remove('hidden');
      });
    }


  hideTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = true
    });
  }

  checkpin() {
    console.log("checkpin")
    // checksum is coded by this: pin_code.split("").map(&:ord).sum
    // so make an a checksum of the input and compare
    var pin_code = this.pininputTarget.value
    var checksum = this.checksumTarget.value
    var input_checksum = pin_code.split("").map(c => c.charCodeAt(0)).reduce((a, b) => a + b, 0)
    console.log("checksum", checksum)
    console.log("input_checksum", input_checksum)
    if (checksum == input_checksum) {
      console.log("correct pin")
      this.showpinTargets()
    }
  }

  toggleTargets() {
    console.log("BLa")
      this.hideableTargets.forEach((el) => {
      el.classList.toggle("hidden");
      //el.hidden = !el.hidden
    });
  }
}