import { Controller } from "stimulus"

export default class extends Controller {
    static values = { seconds: Number };
    static targets = ["output", "modal", "modalCounter", "progressCircle"];
    static classes = ["counting", "stopped", "reset"]

    downloadTimer = null;
    timeleft = 0;
    stopped = false;
    
    connect() {
        // Initialize
    }

    starttimer() {
        let that = this;
        const totalSeconds = this.secondsValue;
        const circumference = 2 * Math.PI * 45; // 45 is the radius of our circle
        
        // Show modal
        this.modalTarget.classList.remove('hidden');
        
        this.downloadTimer = setInterval(function () {
            if (that.timeleft <= 0) {
                clearInterval(that.downloadTimer);
                if (that.hasOutputTarget) {
                    that.outputTarget.innerHTML = "0";
                }
                that.element.classList.remove(that.stoppedClass);
                that.element.classList.add(that.resetClass);
                that.element.classList.remove(that.countingClass);
                
                // Hide modal when done
                that.hideModal();
            } else {
                if (that.hasOutputTarget) {
                    that.outputTarget.innerHTML = that.timeleft;
                }
                if (that.hasModalCounterTarget) {
                    that.modalCounterTarget.textContent = that.timeleft;
                }
                if (that.hasProgressCircleTarget) {
                    // Calculate progress and update circle
                    const progress = that.timeleft / totalSeconds;
                    const dashoffset = circumference * (1 - progress);
                    that.progressCircleTarget.style.strokeDashoffset = dashoffset;
                }
            }
            that.timeleft -= 1;
        }, 1000);
    }

    start(event) {
        if (this.downloadTimer !== null) {
            if (this.stopped === false) {
                this.stopped = true;
                clearInterval(this.downloadTimer);
                if (this.timeleft <= 0) {
                    this.timeleft = this.secondsValue;
                    this.element.classList.remove(this.stoppedClass);
                    this.element.classList.add(this.resetClass);
                    this.element.classList.remove(this.countingClass);
                } else {
                    this.element.classList.add(this.stoppedClass);
                    this.element.classList.remove(this.resetClass);
                    this.element.classList.remove(this.countingClass);
                }
            } else {
                this.stopped = false;
                this.starttimer();
                this.element.classList.remove(this.stoppedClass);
                this.element.classList.remove(this.resetClass);
                this.element.classList.add(this.countingClass);
            }
        } else {
            this.timeleft = this.secondsValue;
            this.stopped = false;
            this.starttimer();
            this.element.classList.remove(this.stoppedClass);
            this.element.classList.remove(this.resetClass);
            this.element.classList.add(this.countingClass);
        }
    }

    hideModal() {
        this.modalTarget.classList.add('hidden');
        if (this.hasProgressCircleTarget) {
            this.progressCircleTarget.style.strokeDashoffset = 0;
        }
    }
}
