import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // Hook for future chart interactions
    this.element.addEventListener("chartkick:mouseover", (event) => {
      this.element.style.cursor = "pointer"
    })
    
    this.element.addEventListener("chartkick:mouseout", (event) => {
      this.element.style.cursor = "default"
    })
  }
} 