import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    data: Array
  }

  connect() {
    this.initializeChart()
  }

  initializeChart() {
    const options = {

        legend: {
            show: false
        },
            series: this.generateSeries(),
      chart: {
        height: 350,
        type: 'heatmap',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#0284c7"],
      title: {
        text: 'Training Activity',
        style: {
          fontSize: '14px',
          fontWeight: 500,
          fontFamily: 'Inter, sans-serif'
        }
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const count = series[seriesIndex][dataPointIndex]
          const weekday = w.globals.labels[dataPointIndex]
          const week = w.globals.seriesNames[seriesIndex]
          return `<div class="p-2">
            <div class="font-medium">${weekday}, Week ${week}</div>
            <div>${count} workout${count !== 1 ? 's' : ''}</div>
          </div>`
        }
      },
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: [{
              from: 0,
              to: 0,
              color: '#F3F4F6'
            },
            {
              from: 1,
              to: 1,
              color: '#93C5FD'
            },
            {
              from: 2,
              to: 2,
              color: '#60A5FA'
            },
            {
              from: 3,
              to: 1000,
              color: '#2563EB'
            }]
          }
        }
      },
      xaxis: {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        labels: {
          rotate: 0,
        }
      },
      yaxis: {
        reversed: true,
        labels: {
          formatter: (value) => `Week ${value}`
        }
      }
    }

    const chart = new ApexCharts(this.element, options)
    chart.render()
  }

  generateSeries() {
    // Convert the flat data array into a week-based structure
    const weekData = {}
    const now = new Date()
    const twoMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, now.getDate())

    this.dataValue.forEach(item => {
      const date = new Date(item.x)
      if (date >= twoMonthsAgo) {
        const weekNumber = this.getWeekNumber(date)
        if (!weekData[weekNumber]) {
          weekData[weekNumber] = Array(7).fill(0)
        }
        const dayOfWeek = date.getDay()
        // Convert Sunday (0) to be last day (6)
        const adjustedDay = dayOfWeek === 0 ? 6 : dayOfWeek - 1
        weekData[weekNumber][adjustedDay] = item.y
      }
    })

    // Convert to ApexCharts series format
    return Object.entries(weekData).map(([week, data]) => ({
      name: week,
      data: data
    }))
  }

  getWeekNumber(date) {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    const dayNum = d.getUTCDay() || 7
    d.setUTCDate(d.getUTCDate() + 4 - dayNum)
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  }
} 