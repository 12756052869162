import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    percentage: Number,
    color: String
  }

  connect() {
    const options = {
      series: [this.percentageValue],
      chart: {
        height: 40,
        width: 40,
        type: 'radialBar',
        sparkline: {
          enabled: true
        }
      },
      colors: [this.colorValue],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '50%',
          },
          dataLabels: {
            show: false
          },
          track: {
            background: '#E5E7EB'
          }
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        }
      }
    }

    this.chart = new ApexCharts(this.element, options)
    this.chart.render()
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy()
    }
  }
} 